import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`Distinctions`}</h4>
    <p><strong parentName="p">{`Acronist of the Basentia`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <p><strong parentName="p">{`Meticulous`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <p><strong parentName="p">{`Amateur Archealogist`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <h4>{`Attributes`}</h4>
    <p><em parentName="p">{`Strength`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
`}<em parentName="p">{`Dexterity`}</em>{` `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
`}<em parentName="p">{`Intellect`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
`}<em parentName="p">{`Will`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
`}<em parentName="p">{`Awareness`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
`}<em parentName="p">{`Conviction`}</em>{` `}<inlineCode parentName="p">{`jsx:<D10/>`}</inlineCode></p>
    <h4>{`Skills`}</h4>
    <p>{`Craft `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
Fight `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Fix `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Focus `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Influence `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
Labor `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Move `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Know `}<inlineCode parentName="p">{`jsx:<D10/>`}</inlineCode>{`
Notice `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Operate `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Perform`}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
Shoot `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
Sneak `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Survive `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Treat `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
Trick `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode></p>
    <h4>{`Luminous Magic`}</h4>
    <p><strong parentName="p">{`Convictions of Energy`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Adam La Cour can manifest Convictions that manipulate the very forces of reality, storing and releasing potential energy in objects or manifesting kinetic energy in the form of blasts.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Fixed Truth`}</em>{`: When manifesting a conviction related to Energy, spend a PP to create a `}<inlineCode parentName="li">{`jsx:<D8/>`}</inlineCode>{` asset out of the manifestation.`}</li>
    </ul>
    <h3>{`Signature Asset`}</h3>
    <p>{`Locked Journal of Notes `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      